.loader_overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #F7F9FF;
}

.loader {
    z-index: 1010;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90%;
}

.loader_content {
    max-width: 100%;
    width: 500px;
    text-align: center;
}

.loader_graphic {
    animation: spin 4s linear infinite;
}

.loader_title {
    font-size: 16px;
    color: #fff;
    margin-top: 25px;
}

.loader_info {
    font-size: 16px;
    color: #797F8E;
    margin-bottom: 0;
}

.loader_info a {
    color: #fff;
}

@keyframes spin { 
    100% {  
        transform:rotate(360deg);
    }
}