.Select_Label {
	font-size : 15px;
	line-height : 18px;
	color : #6A6D76;
	display : block;
	margin-bottom : 8px;
}

.Select_Wrapper {
	overflow : hidden;
	position : relative;
	margin: 0 0 1rem;
}

.Select_Select {
	font-size : 15px;
	line-height : 19px;
	color : #6A6D76;
	border : #DCE2EA solid 1px;
	background-color : #FFFFFF;
	border-radius : 3px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none !important;
	width : 100%;
	height : auto;
	margin-bottom : 0;
	padding: 14px 19px;
	background-image:none;
}

.Select_Select:focus{
	border-color: #00D99F;
}

.Select_Icon {
	position : absolute;
	top : 50%;
	right : 19px;
	transform : translateY(-50%);
	pointer-events : none;
}

.Select_Select Option{
	border-color: #00D99F;
}

.InputSelect_Validation_Wrapper {
	position: absolute;
	right:12px;
	top:0px;
	pointer-events: none;
}
.InputSelect_Validation_Inner_Wrapper{
	height: 48px;
	display: flex;
	align-items: center;
}

