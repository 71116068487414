.ModalAddUser {
	background-color : #2B2B2B;
	text-align : left;
}

.ModalWindow {
	background-color : #FFFFFF;
	border-radius : 8px;
}

.ModalTitle {
	text-align : center;
	display : block;
	padding-top: 20px;
}

.AddANewUser_H2 {
	color : #1D1764;
}

.FormWrapper {
	text-align : left;
	display : block;
}

.InputWrapper {
	text-align : left;
	display : block;
}

.ButtonWrapper {
	text-align : right;
	display : block;
	padding-top : 20px;
}

.ButtonPrimary {
	min-width : 137px;
}

.Error{
	color: red;
}
