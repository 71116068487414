.Content {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
	padding : 68px 0;
}

.TitleWrapperWrapper {
    margin-bottom : 60px;
    text-align: center;
}

.TitleWrapperWrapper_Title {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.EmployeeWrapper {
	font-size: 16px;
    line-height: 27px;
	color: #6A6D76;
	margin-bottom: 16px;
	width: 100%;
    text-align: left;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.06);
    border-radius: 3px;
    text-align: left;
	padding: 18px 19px 15px 19px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
}

.EmployeeName{
	flex-basis: 20%;
	flex-shrink: 0;
}

.EmployeeDisabled{
	background-color:#FBFCFF;
	cursor:	not-allowed;
}

.IsInExistingWrapper{
	display: flex;
	justify-content: center;
	flex-basis: 60%;
}


.IsInExisting {
	font-size: 16px;
	color: #1D1764;
	margin-left: 12px;
} 

.ScheduleName {
	display: block;
}

.Button {
	min-width : 109px;
	min-height : 48px;
	margin-top: 8px;
}

.Error {
	color: red;
}

.ListHeader {
	display: flex;
	justify-content: space-between;
	margin: 12px 29px 12px 18px;
	color: #6A6D76;
}

.NumberEmployees {
	font-size: 17px;
	line-height: 20px;
	color: #1D1764;
	margin-right: 17px;
}

.Breadcrum{
	font-size: 17px;
	line-height: 20px;
	color: #1D1764;
	margin-bottom: 20px;
}

.BreadcrumLink{
	font-size: 17px;
	line-height: 20px;
	color: #1D1764;
	text-decoration: underline;
}

.NoEmployeesWarning{
	text-align: center;
	font-size: 17px;
	line-height: 20px;
	color: #1D1764;
	margin: 20px;
}

.Icon {
	height: 25.6px;
	width: 25.6px;
}