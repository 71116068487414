.ModalDeleteUser {
	background-color : #2B2B2B;
	text-align : left;
}

.ModalWindow {
	background-color : #FFFFFF;
	border-radius : 8px;
}

.ModalTitle {
	text-align : center;
	display : block;
	padding-top: 20px;
}

.DeleteUser_H2 {
	color : #1D1764;
}

.Wrapper {
	text-align : center;
	display : block;
}

.WrapperWithPadding {
	text-align : center;
	display : block;
	padding-bottom : 10px;
}

.AreYouSureYouWan_H2 {
	color : #6A6D76;
}

.ButtonDestructive {
	min-width : 228px;
}

.Cancel_H2 {
	color : #1D1764;
}
.Error{
	color: red;
}
