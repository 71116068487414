.DatabasePathSearchWrapper{
    display: flex;
    flex-direction: column;
}

.DatabasePathSearchItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.DatabasePathSearchText{
    font-size : 15px;
	line-height : 18px;
	color : #6A6D76;
}