.Header {
	text-align : left;
	display : block;
}

.Content {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
	padding: 30px 0 74px 0;
}

@media screen and (min-width:645px) {
	.Content{
		padding-top : 74px;
	}
}

.Row {
	margin-bottom : 60px;
	text-align : left;
	display : block;
}

.SettingTitleWrapper {
	margin-bottom : 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.PaymentSchelduesWrapper{
	margin : 40px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

@media screen and (min-width:645px) {
	.SettingTitleWrapper {
		flex-direction: row;
	}
}

.TitleWrapperWrapper {
	margin-bottom : 26px;
}

.TitleWrapperWrapper_Title {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.ButtonPrimaryWrapperWrapper {
	width:100%;
}

@media screen and (min-width:645px) {
	.ButtonPrimaryWrapperWrapper {
		width:137px;
		position: absolute;
		right: 0px;
		top:-13px;
	}
}

.ButtonPrimary {
	width:100%;
	min-width : 137px;
}

.Error{
	color: red;
	text-align: right;
	margin-top: 10px;
}


.UsersWrapper{
	margin-bottom: 80px;
}

.ScheduleDateWrapper{
	margin-bottom: 12px;
}

.ScheduleDateButton{
	position: relative;
	cursor: pointer;
	font-size: 17px;
    line-height: 27px;
	color: #6A6D76;
	width:100%;
	text-align: left;
	background-color: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.06);
    border-radius: 3px;
    text-align: left;
    padding: 18px 19px 15px 19px;
}

.ScheduleDateButton:after{
	content: '';
	background-image: url('../../Images/IconChevronDown.svg');
	position: absolute;
	right: 19px;
	top:28px;
	width: 12px;
	height:8px;
}



.ScheduleDateTitle{
	font-size: 15px;
    line-height: 15px;
	color: #6A6D76;
	margin-bottom: 10px;
}

.ButtonWrapper {
	text-align : right;
	display : block;
	padding-top : 30px;
}

.ButtonPrimary {
	width : 139px;
}

.DatabasePathWrapper{
	position: relative;
}

.DatabasePath {
	line-height: 21px;
}

.ScheduleDate{
	font-size: 17px;
    line-height: 27px;
	color: #6A6D76;
	margin-bottom: 10px;
	width: 100%;
    text-align: left;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.06);
    border-radius: 3px;
    text-align: left;
    padding: 18px 19px 15px 19px;
}

.AddScheduleDate {
	font-size : 15px;
	line-height : 22px;
	color : #1D1764;
	display : inline-block;
	border-bottom: 1px solid #1D1764;
	cursor: pointer;
	margin-top: 4px;
}

.ReadOnlyInput{
	display : block;
	margin-bottom : 16px;
}