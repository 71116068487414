.Checkbox_Wrapper {
	display : block;
	position : relative;
	text-align : left;
}

.Checkbox_Label {
	cursor : pointer;
	float : left;
	font-size : 16px;
	line-height : 16px;
	color : #6A6D76;
	transform : translateY(2px);
}

.Checkbox_Input {
	position : absolute;
	top : 0;
	left : 0;
	opacity : 0;
	width : 20px;
	height : 20px;
	margin : 0px 9px 0px 0px;
	cursor : pointer;
}

.Checkbox_Input + .Checkbox_Input_Custom {
	float : left;
	width : 20px;
	height : 20px;
	background-image : url('../../../Images/Unchecked.svg');
	margin : 0px 9px 0px 0px;
	background-repeat: no-repeat;
}

.Checkbox_Input:checked + .Checkbox_Input_Custom {
	background-image : url('../../../Images/Checked.svg');
	background-repeat: no-repeat;
}

