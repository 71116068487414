.ModalAddUser {
	background-color : #2B2B2B;
	text-align : left;
}

.ModalWindow {
	background-color : #FFFFFF;
	border-radius : 8px;
}

.ModalTitle {
	text-align : center;
	display : block;
	padding-top: 20px;
}

.AddANewUser_H2 {
	color : #1D1764;
}

.FormWrapper {
	text-align : left;
	display : block;
}

.FormSection{
	padding-top: 28px;
	padding-bottom: 42px;
	border-bottom: 1px solid #DCE2EA;
}
.FormSection:last-of-type{
	border-bottom: none;
}

.FormText{
	font-size: 15px;
    line-height: 18px;
	color: #6A6D76;
	margin-bottom: 8px;
}
.FormTextWithInput{
	margin-bottom: 45px;
}
.FormTextWithInput:first-child{
	margin-right: 8px;
}
.FormTextWithInput:last-child{
	margin-left: 8px;
}

.InputWrapper {
	text-align : left;
	display : block;
}

.ButtonWrapper {
	text-align : right;
	display : block;
}

.ButtonGhost {
	min-width : 137px;
}

.Error{
	color: red;
}

.FormImputEverWeeks{
	width: 50px;
	margin: 0;
    display: inline-block;
}

.FormImputEverWeeksWrapper{
	display: flex;
	align-items: baseline;
}


@media screen and (min-width:645px) {
	.PaymentDate{
		margin-right:10px;
	}

	.PaymentDay{
		margin-left: 10px;
	}
}