.ButtonDestructive {
	text-align : center;
	background-color : #FF5656;
	border-radius : 3px;
	padding : 16px 14px 14px 14px;
	font-size : 17px;
	line-height : 20px;
	color : #FFFFFF;
	cursor : pointer;
}

