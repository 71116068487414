.Header {
	text-align : left;
	display : block;
}

.Content {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
	padding: 30px 0 74px 0;
}

@media screen and (min-width:645px) {
	.Content{
		padding-top : 74px;
	}
}

.Row {
	margin-bottom : 60px;
	text-align : left;
	display : block;
}

.SettingTitleWrapper {
	margin-bottom : 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

@media screen and (min-width:645px) {
	.SettingTitleWrapper {
		flex-direction: row;
	}
}

.TitleWrapperWrapper {
	margin-bottom : 30px;
}

.TitleWrapperWrapper_Title {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.ButtonGhostWrapperWrapper {
	width:100%;
}

@media screen and (min-width:645px) {
	.ButtonGhostWrapperWrapper {
		width:137px;
		position: absolute;
		right: 10px;
		top:-13px;
	}
}

.ButtonGhost {
	width:100%;
	min-width : 137px;
}

.Error{
	color: red;
}


.UsersWrapper{
	margin-bottom: 80px;
}

.ScheduleDateWrapper{
	margin-bottom: 12px;
}

.ScheduleDateButton{
	position: relative;
	cursor: pointer;
	font-size: 17px;
    line-height: 27px;
	color: #6A6D76;
	width:100%;
	text-align: left;
	background-color: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.06);
    border-radius: 3px;
    text-align: left;
    padding: 18px 19px 15px 19px;
}

.ScheduleDateButton:after{
	content: '';
	background-image: url('../../Images/IconChevronDown.svg');
	position: absolute;
	right: 19px;
	top:28px;
	width: 12px;
	height:8px;
}



.ScheduleDateTitle{
	font-size: 15px;
    line-height: 15px;
	color: #6A6D76;
	margin-bottom: 10px;
}

.ButtonWrapper {
	text-align : right;
	display : block;
}

.ButtonGhost {
	width : auto;
}

.DatabasePathWrapper{
	position: relative;
}

.DatabasePathPrefix{
	position: absolute;
	left:8px;
	top:42px;
	font-size: 15px;
    line-height: 15px;
	color: lightgray;
}

.DatabasePathInput{
	padding-left: 212px;
}
.PaymentSchelduesWrapper{
	margin-top : 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.AddScheduleDate {
	font-size : 15px;
	line-height : 22px;
	color : #1D1764;
	display : inline-block;
	border-bottom: 1px solid #1D1764;
	cursor: pointer;
	margin-top: 4px;
}

.ScheduleDate{

	margin-bottom: 10px;
	width: 100%;
	height: auto;
	text-align: left;
	background-color: #FFFFFF;
	box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.06);
	border-radius: 3px;
	text-align: left;
	padding: 18px 19px 21px 19px;
	cursor: pointer;
	display:flex;
}

.ScheduleDateText{
	display: inline-block;
	font-size: 17px;
	line-height: 27px;
	color: #6A6D76;
	border-bottom: 1px solid #6A6D76;
}

.UsersWrapper{
	margin-bottom: 30px;
}

.ReadOnlyInput{
	display : block;
	margin-bottom : 16px;
}

.ScheduleDateTextWrapper {
	width: 90%;
}

.IconWrapper{
	flex-shrink: 0;
	/*position: absolute;*/
	top: 16px;
	right: 0;
	margin-left:30px;
}

@media screen and (min-width:545px) {
	.IconWrapper {
		position: relative;
		top: 0;
	}
}