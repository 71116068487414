.Header {
	text-align : left;
	display : block;
}

.Content {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
	padding-top : 30px;
}

@media screen and (min-width:645px) {
	.Content{
		padding-top : 74px;
	}
}

.Row {
	margin-bottom : 60px;
	text-align : left;
	display : block;
}

.CardiffElectricWrapperWrapper {
	text-align : left;
}

.CardiffElectricWrapperWrapper_CardiffElectric {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.ButtonGhostWrapperWrapper {
	text-align : left;
}

.ButtonGhost {
	min-width : 137px;
}

.CompanyName {
	display : block;
	margin-bottom : 16px;
}

.CompanyId {
	display : block;
	margin-bottom : 16px;
}

.CompanyEmail {
	display : block;
	margin-bottom : 39px;
}

.ReadOnlyInput{
	display : block;
	margin-bottom : 16px;
}

.PayeReferemceNumber {
	display : block;
	margin-bottom : 20px;
}

.PaymentDate {
	display : block;
	margin-top: 19px;
}
.PaymentDay{
	display : block;
}

@media screen and (min-width:645px) {
	.PaymentDay{
		margin-top: 19px;
	}
}

.PaymentDay label{
	opacity: 0;
}

.ContentWrapper_Users {
	text-align : center;
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
	display : block;
	margin-bottom : 24px;
}

.FullNameWrapperWrapper {
	text-align : left;
}

.FullNameWrapperWrapper_FullName {
	font-size : 17px;
	line-height : 20px;
	color : #6A6D76;
}

.RoleWrapperWrapper {
	text-align : left;
}

.RoleWrapperWrapper_Role {
	font-size : 16px;
	line-height : 19px;
	color : #6A6D76;
}

.IconEdit {
	display : inline-block;
	margin-right : 19px;
}

.IconDelete {
	display : inline-block;
	margin-bottom : 1px;
}

.SettingTitleWrapper {
	margin-bottom : 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

@media screen and (min-width:645px) {
	.SettingTitleWrapper {
		flex-direction: row;
	}
}

.TitleWrapperWrapper {
	margin-bottom : 26px;
	text-align: center;
}

.TitleWrapperWrapper_Title {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.ButtonGhostWrapperWrapper {
	width:100%;
}

@media screen and (min-width:645px) {
	.ButtonGhostWrapperWrapper {
		width:auto;
		position: absolute;
		right: 0px;
		top:-13px;
	}
}

.ButtonGhost {
	width:100%;
	min-width : 137px;
}

.Error{
	color: red;
}


.UsersWrapper{
	margin-bottom: 80px;
}

.ScheduleDateWrapper{
	margin-bottom: 12px;
	display: flex;
}
.ScheduleDateButton{
	position: relative;
	font-size: 17px;
    line-height: 27px;
	color: #6A6D76;
	width:100%;
	text-align: left;
	background-color: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.06);
    border-radius: 3px;
    text-align: left;
	padding: 18px 19px 15px 19px;
	
}

.ScheduleDateTitle{
	font-size: 15px;
    line-height: 15px;
	color: #6A6D76;
	margin-bottom: 10px;
}

.ScheduleDate{
	
	margin-bottom: 10px;
	width: 100%;
	height: auto;
    text-align: left;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.06);
    border-radius: 3px;
    text-align: left;
	padding: 18px 19px 21px 19px;
	cursor: pointer;
	display:flex;
}

.ScheduleDateText{
	display: inline-block;
	font-size: 17px;
    line-height: 27px;
	color: #6A6D76;
	border-bottom: 1px solid #6A6D76;
}

.ScheduleDateTextWrapper {
	width: 90%;
}
