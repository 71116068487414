.InputTextReadOnly {
	background-color : #FFFFFF;
	box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
	border-radius : 3px;
	text-align : left;
	padding : 18px 19px 19px 19px;
}

.InputTextReadOnly_Text {
	font-size : 17px;
	line-height : 27px;
	color : #6A6D76;
	word-break: break-all;
}

.InputTextReadOnly_Label {
	font-size: 15px;
    line-height: 15px;
    color: #6A6D76;
    margin-bottom: 10px;
}


.InputText_Input:focus{
	border-color: #00D99F;
	box-shadow: none;outline: 0.8px;
}