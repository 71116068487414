.CheckboxWithLabel_Container {
	margin-bottom: 20px;
	padding: 10px 0;
}

.CheckboxWithLabel_Checkbox {
	display: none;
}

.CheckboxWithLabel_Checkmark {
	float: left;
	width: 18px;
	height: 18px;
	background-image: url('../../../Images/RadioUncheckedSvg.svg');
	margin-right: 10px;
}

.CheckboxWithLabel_Checkbox:checked + .CheckboxWithLabel_Checkmark {
	background-image: url('../../../Images/RadioCheckedSvg.svg');
}

.CheckboxWithLabel_Label {
	font-size: 15px;
    line-height: 18px;
    color: #6A6D76;
	display : block;
	cursor: pointer;
}

.CheckboxWithLabel_Label_Text {
	display: block;
	padding-left: 27px;
}
