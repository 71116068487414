.Header {
	text-align : left;
	display : block;
	padding-bottom : 1px;
}

.Content {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
	padding: 30px 0 74px 0;
}

@media screen and (min-width:645px) {
	.Content {
		padding-top : 75px;
	}
}

.SettingTitleWrapper {
	margin-bottom : 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

@media screen and (min-width:900px) {
	.SettingTitleWrapper {
		flex-direction: row;
	}
}

.TitleWrapperWrapper {
	margin-bottom : 26px;
}

.TitleWrapperWrapper_Title {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.ButtonGhostWrapperWrapper {
	width:100%;
}

@media screen and (min-width:900px) {
	.ButtonGhostWrapperWrapper {
		width:167px;
		position: absolute;
		right: 15px;
		top:-13px;
	}
}

.ButtonGhost {
	width:100%;
	min-width : 167px;
}
.ClinetAdminWrapper{
	margin-top:160px;
}

