.AccountantListItem {
	padding: 19px 0 16px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	border-bottom: 1px solid #DCE2EA;
}

@media screen and (min-width:545px) {
	.AccountantListItem {
		flex-direction: row;
	}
}

.FullNameWrapperWrapper {
	text-align : left;
}

.FullNameWrapperWrapper_FullName {
	font-size : 17px;
	line-height : 20px;
	color : #6A6D76;
}

.RoleWrapperWrapper {
	text-align : left;
	flex-grow: 2;
}
@media screen and (min-width:545px) {
	.RoleWrapperWrapper {
		text-align : right;
	}
}

.RoleWrapperWrapper_Role {
	font-size : 16px;
	line-height : 19px;
	color : #6A6D76;
	word-break: break-all;
}

.IconWrapper{
	flex-shrink: 0;
	position: absolute;
	top: 16px;
	right: 0;
	margin-left:30px;
}

@media screen and (min-width:545px) {
	.IconWrapper {
		position: relative;
		top: 0;
	}
}

.IconEdit {
	display : inline-block;
	margin: 0 19px 0 29px;
	cursor: pointer;
}

.IconDelete {
	display : inline-block;
	margin-bottom : 1px;
	cursor: pointer;
}

