.Header {
	text-align : left;
	display : block;
}

.Content {
	
	position: relative;
}

.ContentWrapper{
	background-color : #F7F9FF;
	text-align : left;
	display : block;
	padding-top : 74px;
	margin: 0 1em 40px;
}

.Row {
	margin-bottom : 26px;
	text-align : left;
}

.TitleWrapperWrapper {
	text-align : center;
}

.TitleWrapperWrapper_Title {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.TitleWrapperWrapper_SubTitle {
	font-size : 14px;
	line-height : 25px;
	color : #1D1764;
	margin-top: 4px;
}


@media screen and (min-width:1024px) {
	.TitleWrapperWrapper {
		margin-bottom: 20px;
	}
}

