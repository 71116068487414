.Header {
	text-align : left;
	display : block;
}

.Content {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
}

.Row {
	margin-bottom : 26px;
	text-align : left;
}

.TitleWrapperWrapper {
	text-align : left;
}

.TitleWrapperWrapper_Title {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.EmployeesWrapperWrapper {
	text-align : left;
	display : block;
	padding-bottom : 16px;
}

.EmployeesWrapperWrapper_Employees {
	font-size : 17px;
	line-height : 22px;
	color : #1D1764;
	display : inline-block;
	margin-right : 101px;
}

.EmployeesWrapperWrapper_Payroll {
	font-size : 17px;
	line-height : 22px;
	color : #1D1764;
	display : inline-block;
}

.RectangleWrapperWrapper {
	text-align : left;
	display : block;
	padding : 3px 1px 0px 1px;
}

.ButtonDropdownSmallCopy {
	text-align : left;
}

.ButtonDropdownSmall {
	border-radius : 3px;
	background-image : linear-gradient(to right, #00D99F, #23F3BB);
	padding : 15px 25px 15px 25px;
}

.ButtonDropdownSmall_Value {
	font-size : 17px;
	font-weight : bold;
	line-height : 20px;
	color : #FFFFFF;
	display : block;
	margin-bottom : 15px;
}

.SearchResultsHeader {
	text-align : left;
	margin-bottom : 11px;
}

.PayPeriodWrapperWrapper {
	text-align : left;
}

.PayPeriodWrapperWrapper_PayPeriod {
	font-size : 16px;
	line-height : 22px;
	color : #6A6D76;
}

.StartDateWrapperWrapper {
	text-align : left;
}

.StartDateWrapperWrapper_StartDate {
	font-size : 16px;
	line-height : 22px;
	color : #6A6D76;
}

.EndDateWrapperWrapper {
	text-align : left;
}

.EndDateWrapperWrapper_EndDate {
	font-size : 16px;
	line-height : 22px;
	color : #6A6D76;
}



.PaymentDateWrapperWrapper_PaymentDate {
	font-size : 16px;
	line-height : 22px;
	color : #6A6D76;
	
}

.TotalCostWrapperWrapper {
	text-align : left;
}

.TotalCostWrapperWrapper_TotalCost {
	font-size : 16px;
	line-height : 22px;
	color : #6A6D76;
}

.TotalPayWrapperWrapper {
	text-align : left;
}

.TotalPayWrapperWrapper_TotalPay {
	font-size : 16px;
	line-height : 22px;
	color : #6A6D76;
}


.StatusWrapperWrapper_Status {
	font-size : 16px;
	line-height : 22px;
	color : #6A6D76;
}

.ResultsRow {
	display : block;
	cursor: pointer;
}
.ButtonPrimaryDropdownWrapper{
	display: flex;
    flex-direction: row-reverse;
	margin-bottom: 30px;
}


.Row_FlexWrapper{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin-bottom:16px;
}

.PayPeriodWrapperWrapper_FlexWrapper{
	flex-basis: 33%;
	margin: 0 8px;
}

.PaymentDateWrapperWrapper_FlexWrapper{
	flex-basis: 33%;
	margin: 0 8px;
	text-align: left;
}

.StatusWrapperWrapper_FlexWrapper{
	flex-basis: 33%;
	margin: 0 8px;
	text-align: left;
}

.Error{
	color: red;
}

.ButtonPrimary {
	min-width : 137px;
}



@media print, screen and (max-width: 50em){

	.PaymentDateWrapperWrapper_FlexWrapper{
		display: none;
	}

	.PayPeriodWrapperWrapper_FlexWrapper{
		flex-basis: 50%;
	}
	
	
	.StatusWrapperWrapper_FlexWrapper{
		flex-basis: 50%;
	}

}