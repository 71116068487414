.InputText_Label {
	cursor : pointer;
	font-size : 15px;
	line-height : 18px;
	color : #6A6D76;
	display : block;
	margin-bottom : 8px;
}

.InputText_Wrapper {
	position : relative;
}

.InputText_Input {
	width : 100%;
	height : auto;
	border : #DCE2EA solid 1px;
	background-color : #FFFFFF;
	box-shadow : none;
	border-radius : 3px;
	text-align : left;
	display : block;
	padding : 14px 19px;
	font-size : 15px;
	line-height : 18px;
	color : #797F8E;
	border-shadow:none;
}

.InputText_Input:focus{
	border-color: #00D99F;
	box-shadow : 0px 2px 2px 0px #F2F2F2;
}

.InputText_ToolTip_Wrapper {
	position: absolute;
	right:12px;
	top:0px;
}

.InputText_ToolTip{
	cursor: pointer;
}

.InputText_Validation_Wrapper {
	position: absolute;
	right:12px;
	top:0px;
}
.InputText_Validation_Inner_Wrapper{
	height: 48px;
	display: flex;
	align-items: center;
}