.InputTextWithIcon_Label {
	cursor : pointer;
	font-size : 15px;
	line-height : 18px;
	color : #6A6D76;
	display : block;
	margin-bottom : 8px;
}

.InputTextWithIcon_Wrapper {
	position : relative;
}

.InputTextWithIcon_Input {
	width : 100%;
	height : auto;
	text-align : left;
	display : block;
	font-size : 15px;
	line-height : 18px;
	color : #6A6D76;
	padding-top : 14px;
	padding-bottom : 14px;
	padding-left : 36px;
	border: 1px solid #DCE2EA;
	border-radius: 3px;
	box-shadow: none;
}

.InputTextWithIcon_Input:focus{
	border-color: #00D99F;
}

.InputTextWithIcon_Icon {
	position : absolute;
	top : 10px;
	left : 7px;
}

@media print {
	.Search{
		display: none;
	}
}

