.Tr {
	background-color : #FFFFFF;
	box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
	border-radius : 3px;
	margin-bottom: 16px;
	cursor: pointer;
	width:100%;
}

.Row {
	text-align : left;
}

.TextColumn2WrapperWrapper {
	text-align : left;
	padding : 17px 12px 21px 12px;
}

.TextColumn2WrapperWrapper_TextColumn2 {
	font-size : 16px;
	line-height : 18px;
	color : #6A6D76;
	border-bottom: 1px solid #6A6D76;
}

.TextColumn1WrapperWrapper {
	text-align : right;
	padding : 17px 10px 16px 10px;
}

.TextColumn1WrapperWrapper_TextColumn1 {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
	
}

