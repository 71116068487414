.ItemWrapper{
	position: relative;
	margin-bottom: 20px;
	background-color : #FFFFFF;
	box-shadow : 0px 2px 12px 0px rgba(0,0,0,0.06);
	border-radius : 3px;
	text-align : left;
	font-size: 16px;
    line-height: 27px;
    color: #6A6D76;
}

.DetailsWrapper{
	padding: 10px;
}

.TotalWrapper{
	text-align: right;
	border-top: 1px solid #EBEBEB;
	padding: 10px;
}

.AdditionalItem{
	background-color: #f7f9ff;
	border: 1px solid #EBEBEB;
	border-radius: 20px;
	width: max-content;
	padding: 2px 5px;
	display: inline-block;
	vertical-align:top;
    margin-right: 4px;
	margin-bottom: 4px;
	font-size: 14px;
}

.AdditionalItemText{
	font-size: 14px;
	line-height: 16px;
}

.AdditionalItemsList {
	list-style-type: none;
	margin:0;
	padding:0;
}

.PaymentDeductionWrapper{
	margin-bottom: 12px;;
}

.PaymentDeductionWrapper:last-child{
	margin-bottom: 0;
}

.Plus{
	color: #00D99F;
	font-weight: bold;
	line-height: 20px;
    font-size: 20px;
	padding-right: 4px;
	display: inline;
	text-align: center;
}
.Negative{
	color: #E94F4F;
	font-weight: bold;
	line-height: 20px;
    font-size: 20px;
	padding-right: 4px;
	display: inline;
	text-align: center;
}

.TotalsPlus{
	color: #00D99F;
	font-weight: bold;
	line-height: 20px;
    font-size: 20px;
	width: 23px;
	display: inline-block;
	text-align: center;
}
.TotalsNegative{
	color: #E94F4F;
	font-weight: bold;
	line-height: 20px;
    font-size: 20px;
	width: 23px;
	display: inline-block;
	text-align: center;
}


.Name{
	display: block;
	cursor: pointer;
	line-height: 16px;;
	color: #6A6D76;
	margin-top: 12px;
}

.NiNumber{
	margin-top: 4px;
}

.AdditionalWrapper{
	min-height: 1px;
	margin: 12px 0;
	
}

.NoMargin{
	margin: 0 !important;
}

.PaymentDeductionAmount{
	text-align: right;
}

.EditWrapper{
	position: absolute;
	top: 4px;
	right: 10px;
}

/* .SaveWrapper{
	position: absolute;
	bottom: 81px;
	right: 10px;
} */

.SaveBtn{
	min-width: 137px;
}

.Input{
	padding: 8px;
	margin: 0;
}

.InputRightAlign{
	text-align: right;
}

.Error{
	color: red;
	text-align: left;
    margin-top: 12px;
}

.SaveWrapper{
	background-color: #e4e9f6;
	width: 100%;
	text-align: right;
	padding: 24px;
	position: fixed;
	bottom: -110px;
	left: 0;
	transition: all 0.5s;
	z-index: 999;
}

.SaveActive{
	bottom: 0;
}



@media screen and (min-width:1024px) {
	.AdditionalWrapper{
		margin: 0;
		padding: 0 20px 0 0 !important;
	}

	.NoPadding{
		padding: 0 !important;
	}
	.Name{
		margin-top: 0px;
	}
}

/* hide number arrows on input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



@media print {
	.ItemWrapper{
		border: 1px solid #EBEBEB;
		margin-bottom: 40px;
		font-size: 12px;
		break-inside: avoid;
	}

	.Plus{
		font-size: 12px;
	}

	.Negative{
		font-size: 12px;
	}

	.Name{
		margin-top: 0px;
		border: none;
	}

	.PaymentDeductionCol{
		width: 35%;
	}

	.AdditionalWrapper{
		margin: 0;
		padding: 0 0.4rem;
	}

	.CheckboxWrapper{
		width:0%;
	}

	.NameWrapper{
		width: 100%;
	}

}

.IconDelete {
	margin-top:-7px;
	cursor: pointer;
}

.NoPadding{
	padding: 0 !important;
}

.modal_content {
	background-color: red;
}