.Header {
	background-color : #1D1764
}

.Row {
	text-align : left;
}

.LogoWrapperWrapper {
	text-align : left;
	padding : 16px;
	cursor: pointer;
}

.AvatarWrapper {
	text-align : right;
	padding : 16px 12px 16px 12px;
}
.MenuWrapper{
	position: relative;
}

.DropdownWrapper{
	width:176px;
	z-index: 999999;
	position: absolute;
	right: 10px;
    top: 64px;
}

@media print {
	.Header{
		display: none;
	}
}