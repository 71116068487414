.Header {
	text-align : left;
	display : block;
}

.Content {
	background-color : #F7F9FF;
	text-align : left;
	display : block;
	padding: 74px 0;
}

.Row {
	margin-bottom : 52px;
}

.TitleWrapperWrapper {
	text-align : center;
}

.TitleWrapperWrapper_Title {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.SearchWrapperWrapper {
	text-align : left;
}

.SearchResultsHeader {
	text-align : left;
	margin-bottom : 11px;
}

.NameWrapperWrapper {
	text-align : left;
}

.NameWrapperWrapper_Name {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.CompanyIdWrapperWrapper {
	text-align : right;
}

.CompanyIdWrapperWrapper_CompanyId {
	font-size : 16px;
	line-height : 27px;
	color : #6A6D76;
}

.SearchResult {
	display : block;
}
.SettingTitleWrapper {
	margin-bottom : 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

@media screen and (min-width:645px) {
	.SettingTitleWrapper {
		flex-direction: row;
	}
}

.TitleWrapperWrapper {
	margin-bottom : 26px;
}

.TitleWrapperWrapper_Title {
	font-size : 21px;
	line-height : 25px;
	color : #1D1764;
}

.ButtonGhost {
	width:100%;
	min-width : 139px;
}

.ButtonGhostWrapperWrapper {
	width:100%;
}

@media screen and (min-width:645px) {
	.ButtonGhostWrapperWrapper {
		width:auto;
		position: absolute;
		right: 0px;
		top:-13px;
	}
}


