.Dialog {
	position: relative;
	width: 650px;
	max-width: 95vw;
	background-color : #FFFFFF;
	border-radius : 8px;
	padding: 30px;
}

.DialogLarge {
	width: 1410px;
	padding-top: 30px;
}

.DialogMedium {
	width: 1012px;
}

.Dialog h2 {
	text-align: center;
	margin-bottom: 2em;
}

.Close {
	position: absolute;
	top: 16px;
	right: 16px;
}

.Dialog .SecondaryButton {
	width: 100%;
	margin-top: 10px;
}

.Modal {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}