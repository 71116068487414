.SelectDayOfMonth{
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #DFE3E8;
    border-left: 1px solid #DFE3E8;
}
.SelectDayOfMonthButton{
    font-size: 15px;
    line-height: 18px;
    color: #6A6D76;
    width: 14.2%;
    height: 40px;
    border-right: 1px solid #DFE3E8;
    border-bottom: 1px solid #DFE3E8;
}

.SelectDayOfMonthButtonSelected{
    font-size: 15px;
    line-height: 18px;
    color: #FFF;
    background-color: #1C1764;
    width: 14.2%;
    height: 40px;
    border-right: 1px solid #1C1764;
    border-bottom: 1px solid #1C1764;
}